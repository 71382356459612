import React from 'react'
import {
	string,
	node,
	oneOf,
	oneOfType,
	func,
	element,
	object,
	bool
} from 'prop-types'
import classNames from 'classnames'
import Node from '@/utils/Node'

function ButtonGradient({
	as = 'button',
	children,
	colour = 'purple',
	textColour = 'white',
	className,
	track = true,
	hasGradient = true,
	...rest
}) {
	return (
		<Node
			as={as}
			className={classNames(
				className,
				{'pardotTrackClick': track},
				`text-${textColour}`,
				hasGradient ? `bg-gradient-to-l from-${ colour }1 to-${ colour }${+hasGradient ? 2 : 1}` : `bg-${colour}1`,
				'transition-all inline-flex items-center justify-center text-md text-left font-museoSansRegular py-2 px-8 hover:px-16 relative rounded-full'
			)}
			{...rest}
		>
			<span>{children}</span>
		</Node>
	)
}

ButtonGradient.propTypes = {
	as: oneOfType([string, node, func, element, object]),
	children: node,
	className: string,
	colour: oneOf(['purple', 'orange', 'yellow', 'green', 'cyan', 'darkgrey', 'lightgrey']),
	hasGradient: bool
}

export default ButtonGradient
