/* eslint-disable react/prop-types */
import React from 'react'
import { string, object } from 'prop-types'
import classNames from 'classnames'
import Link from '@/utils/Link'
import SectionHeading from '@/components/global/SectionHeading'
import Image from '@/utils/Image'
import IconWrapper from '@/utils/IconWrapper'
import Arrow from '@/icons/upwards-arrow.svg'

function CaseStudyCard({
	className,
	title,
	image,
	path,
	label = 'Case study'
}) {
	return (
		<div
			className={classNames(
				'w-full md:w-1/2 lg:w-1/3 md:pl-4 lg:pl-8 group',
				className
			)}
		>
			<div className="relative aspect-ratio-square md:aspect-ratio-5/9">
				<div className="absolute inset-0">
					{image && (
						<Image style={{ width: '100%', height: '100%' }} {...image} />
					)}
					<div className="absolute inset-0 bg-black-30 transition-bg-color group-hover:bg-primary-90">
						<div className="absolute inset-0 z-10 flex flex-col p-8">
							<div className="flex items-start justify-between">
								<h2 className="w-full leading-snug text-white text-h4-fluid md:text-2xl font-museoSansRegular" dangerouslySetInnerHTML={{__html:title}} />
							</div>

							<div className="flex items-center justify-between mt-auto">
								<SectionHeading className="text-xs text-white" size="w-16">
									{label}
								</SectionHeading>
								<Link
									to={path}
									className="flex-shrink-0 w-10 md:w-12 rotate-90"
								>
									<IconWrapper
										className="w-full text-white stroke-current"
										label="Read more"
										icon={Arrow}
									/>
								</Link>
							</div>

							<Link className="absolute inset-0 z-20 block" to={path}>
								<div className="sr-only">Read more</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

CaseStudyCard.propTypes = {
	title: string.isRequired,
	path: string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	image: object
}

export default CaseStudyCard
