/* eslint-disable react/prop-types */
import React from 'react'
import Image from '@/utils/Image'
import Form from '@/components/Form'
import useForm from '@/hooks/useForm'

const defaultHeading =
	'Be the first to know\nGet all the latest\ncurated news on\nhaptics and next\ngeneration interfaces, straight\nto your inbox.'

function ContactForm({
	copy,
	heading = defaultHeading,
	form_image,
	saleforces_form_id = 3504,
}) {
	const { nodes } = useForm()
	const form = nodes.find(item => item.wordpress_id === saleforces_form_id)

	return (
		<div id="form-block" className="overflow-hidden bg-lightgrey">
			<div className="px-8 wrapper">
				<div className="max-w-6xl mx-auto">
					<div className="py-20 mx-auto lg:flex lg:py-32 lg:-ml-32">
						<div className="w-full mb-8 text-left md:pr-10 lg:flex lg:flex-col lg:pl-32 lg:w-1/2">
							<h2 className="max-w-lg pb-6 leading-tight md:text-left text-darkgrey text-h2-fluid font-museoSans">
								{heading}
							</h2>
							{copy && <p className="mb-8 text-left md:w-3/3">{copy}</p>}
							<p className="mb-8 md:w-3/3">
								By signing up, you are agreeing to our{' '}
								<a
									className="underline pardotTrackClick text-primary"
									href="/privacy-policy/"
									rel="nofollow"
									target="_blank"
								>
									privacy policy
								</a>
								.
							</p>
							{/* {form_image && (
								<div className="w-full">
									<Image {...form_image} />
								</div>
							)} */}
						</div>
						<div className="w-full lg:flex lg:flex-col mb lg:pl-32 lg:w-1/2 form-fix">
							<Form {...form.acf} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactForm
