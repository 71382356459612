import { useStaticQuery, graphql } from 'gatsby'
// eslint-disable-next-line no-unused-vars
import { formQuery } from '@/queries'

function useForm() {
	const data = useStaticQuery(graphql`
		{
			form: allWordpressWpForm {
				...formQuery
			}
		}
	`)

	return data.form
}

export default useForm
