/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react'
import useMediaQuery from '@/hooks/useMediaQuery'
import Slider from 'react-slick'
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames'
import IconWrapper from '@/utils/IconWrapper'
import Arrow from '@/icons/arrow.svg'
import ButtonGradient from '@/components/global/ButtonGradient'
import AnimateEntrance from '@/utils/AnimateEntrance'
import Link from '@/utils/Link'
import { containerVariants } from '@/utils/transitions'
import styles from './SimpleSlider.module.css'


function SimpleSlider({
    heading,
    copy,
	slides,
}) {
	const isMobile = useMediaQuery('(max-width: 48em)')
	const [imageSlide, setImageSlide] = useState()
	const [contentSlide, setContentSlide] = useState()

	const imageSlider = useRef()
	const contentSlider = useRef()

	const sliderSettings = {
		className: styles.imageSlider,
		infinite: true,
		variableWidth: true,
		centerMode: false,
		speed: 750,
		arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
		dots: false,
		responsive: [
			{
				breakpoint: 1310,
				settings: {
					centerMode: false
				}
			}
		]
	}

	useEffect(() => {
		if (!imageSlide && !contentSlide) {
			setImageSlide(imageSlider.current)
			setContentSlide(contentSlider.current)
		}
	}, [contentSlide, contentSlider, imageSlide, imageSlider])

	return (
		<AnimateEntrance
			rootMargin="-10% 0px"
			variants={containerVariants}
			onEnter={() => {
				imageSlider.current.slickNext()
			}}
			className="relative overflow-hidden lg:overflow-visible"
		>
			{heading && <h2 className="text-darkgrey text-h2-fluid font-museoSans leading-tight text-center pb-8">{heading}</h2>}
            {copy && (
                <p className="whitespace-pre-line leading-relaxed mx-auto text-center text-darkgrey font-museoSans mb-8">
                    {copy}
                </p>
            )}
			<div className="relative">
				<Slider
					fade={false}
					swipeToSlide
					focusOnSelect
					ref={imageSlider}
					slidesToShow={1}
					{...sliderSettings}
				>
					{slides.map(({title, copy: text, image, mobile_image, cta_text, cta_link}) => (
						<div
							className={classNames(
								'slide-item relative',
								styles.slideItem
							)}
							key={uuidv4()}
						>
                            <div className={classNames(styles.slideItemContent, 'p-16 md:p-8')}>
                                {title && <h3 className="mb-4 text-white font-museoSans text-2xl text-bold">{title}</h3>}
                                {text && <p className="mb-8 text-white">{text}</p>}
                                {cta_link && cta_text && (
                                    <ButtonGradient
                                        as={ Link }
                                        to={ `${ cta_link }` }
                                        className={
                                            classNames(
                                                'mr-4 mb-4 text-md border-white border-2 bg-transparent '
                                            )
                                        }
                                    >
                                        { cta_text }
                                    </ButtonGradient>
                                )}
                            </div>
                            {image && (
                                <div 
                                    className={styles.slideItemBg}
                                    style={{
                                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url('${
											isMobile && mobile_image ? mobile_image.source_url : image.source_url
										}')`
                                    }}
                                />
                            )}
						</div>
					))}
				</Slider>
			</div>
		</AnimateEntrance>
	)
}

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <IconWrapper
            wrapperClassName="absolute top-2/5 right-0 md:right-1 transform -rotate-90 w-20 h-20 cursor-pointer"
            className={classNames(className, 'fill-current text-cyan2')}
            label="Next Arrow"
            icon={Arrow}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <IconWrapper
            wrapperClassName="absolute top-2/5 left-0 md:left-1 transform rotate-90 w-20 h-20 cursor-pointer z-50"
            className={classNames(className, 'fill-current text-cyan2')}
            label="Prev Arrow"
            icon={Arrow}
            onClick={onClick}
        />
    );
}

export default SimpleSlider
