/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React from 'react'
import classNames from 'classnames'

function OneColumnBlock({ copy, header, color, colour, width = 'narrow' }) {
	const textColour = color || colour || 'text-primary'
	return (
		<div className="wrapper">
			<div className={classNames(
				"px-4 mx-auto",
				width === 'narrow' && 'max-w-3xl'  
			)}>
				{header && (
					<h2
						className={classNames(
							'text-h3-fluid w-11/12 mx-auto sm:w-full mb-6 lg:mb-10 font-museoSans text-center leading-tight',
							textColour,
						)}
					>
						{header}
					</h2>
				)}
				<p
					className={classNames(
						'whitespace-pre-line leading-relaxed mx-auto text-center text-darkgrey font-museoSans'
						// { 'text-xl md:text-3xl leading-normal': !header }
					)}
					dangerouslySetInnerHTML={{ __html: copy }}
				/>
			</div>
		</div>
	)
}

export default OneColumnBlock
