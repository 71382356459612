import React from 'react';
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants } from '@/utils/transitions'
import classNames from 'classnames';
import ButtonGradient from '@/components/global/ButtonGradient'
import Image from '../../utils/Image';
import Link from '../../utils/Link';

import styles from './TextImageCtaBlock.module.css';

const TextImageCtaBlock = ({
	image_text_cta_background_colour: bg,
	image_text_cta_background_gradient: gradient,
	image_text_cta_cta_background_colour: linkBgColour,
	image_text_cta_cta_link: link,
	image_text_cta_cta_text_colour: linkTextColour,
	image_text_cta_cta_type: linkType,
	image_text_cta_text: text,
	image_text_cta_text_colour: textColour,
	image_text_cta_title: title,
	image_text_cta_image: image,
	size,
}) => {
	const containerStyle = {
		color: textColour === 'dark' ? 'black' : textColour === 'light' ? 'white' : 'black',
	};

	return (
		<AnimateEntrance
			variants={ containerVariants }
			threshold={ 0 }
			rootMargin="-5px 0px"
			name="container"
		>
			<section
				className={ classNames(
					styles.container,
					`bg-gradient-to-b from-${ bg }1 to-${ bg }${ +gradient ? 2 : 1 }`,
					size === 'narrow' ? 'max-w-4xl mx-auto' : '',
				) }
				style={ containerStyle }
			>

				{ !image ? null :
					<Image className={ styles.image } { ...image } />
				}

				<div className={ styles.textContainer }>
					{ !title ? null :
						<h2 className={ classNames(
							'leading-tight text-h2-fluid font-museoSansBold mb-8',
							styles.title
						) }>
							{ title }
						</h2>
					}

					{ !text ? null :
						<p className={ classNames(
							'mb-4',
							styles.text
						) }>
							{ text }
						</p>
					}

					{ !link.title ? null :
						<ButtonGradient
							as={ Link }
							to={ link.url }
							colour={ linkBgColour }
							target={ link.target }
						>
							{ link.title }
						</ButtonGradient>
					}
				</div>

			</section>
		</AnimateEntrance>
	);
};

export default TextImageCtaBlock;